.blog-post-container {
  background-color: rgba(144, 141, 141, 0.11);
  padding: 2%;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #888888;
  margin: 5%;
  margin-top: 20%;
}

.blog-post-header {
  text-align: left;
}

.blog-post-content {
  margin: 5%;
  text-align: left;
}

@media (min-width: 768px) {
  .blog-post-container {
    padding: 4%;
    margin: 10%;
    margin-top: 10%;
  }

  .blog-post-content {
    margin: 3%;
  }
}

@media (max-width: 576px) {
  .blog-post-content h1 {
    font-size: 2em;
  }
  .blog-post-content h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#about {
  height: 100vh;
}

#BlogContent {
  height: auto;
}

@media (max-width: 576px) {
  #about {
    height: auto;
  }

  #BlogContent {
    height: auto;
  }
}

@media (min-width: 576px) {
  .navbar-nav {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 18px;
  }

  #IntroTitle {
    font-size: 60px;
    font-family: "Ephesis";
  }

  #IntroDescription {
    font-size: 26px;
    font-family: "Dancing Script";
  }

  .widget-title {
    font-size: 11px;
    margin-left: 4px;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
}

@media (min-width: 576px) {
  #IntroTitle {
    font-size: 120px;
    font-family: "Ephesis";
  }

  #IntroDescription {
    font-size: 50px;
    font-family: "Dancing Script";
  }

  .widget-title {
    margin-left: 20px;
  }
}

.display-linebreak {
  white-space: pre-line;
}
body {
  background-color: rgba(144, 141, 141, 0.11);
  text-align: left;
}

.link {
  color: #ff0000;
}
.link:hover {
  color: #87cefa;
}
.widget-title {
  text-align: left;
  height: 150px;
  position: relative;
}

.widget-title h1 {
  font-size: 3.5em;
  position: relative;
  top: 50px;
  left: 22px;
  font-family: Comic Sans MS;
}

.post-container {
  top: 70px;
  background: #4123ad;
  flex-direction: column;
  padding: 2%;
  font-size: 1em;
  color: white;
  border-radius: 10px;
  margin: 2%;
  width: 90%;
  font-family: Comic Sans MS;
}

.post-container h2 {
  color: white;
  font-size: 1.5em;
}

.post-container a {
  color: #e4d2d2;
  font-size: .9em;
}

.info h4 {
  color: white;
  font-size: 1.5em;
}

.heading {
  height: 12px;
  font-size: 0.5em;
  text-align: left;
  display: flex;
  align-items: center;
}

.info {
  margin-top: 19px;
  font-size: 0.5em;
}